export default {
  parseUserProfile: function (data) {
    return {
      userId: data.user_id,
      email: data.email,
      nameInfo: {
        fullName: data.firstname + ' ' + data.lastname,
        firstName: data.firstname,
        lastName: data.lastname,
      },
      connectionInfo: {
        mqttAccessToken: data.access_token,
      },
      authProviderInfo: {
        profilePictureUrl: data.provider_picture,
      },
      permissionInfo: {
        isChektAdmin: !!data.is_chekt_admin,
      },
      statusInfo: {
        createdAt: data.created ? new Date(data.created) : null,
        isEmailVerified: !!data.is_verify,
      },
      companyInfo: {
        address1: data.company_address1,
        address2: data.company_address2,
        email: data.company_email,
        name: data.company_name,
        phone: data.company_phone,
        zipcode: data.company_zipcode,
        logoUrl: data.logo_url,
        lat: Number(data.lat),
        lng: Number(data.lng)
      },
    }
  },
}
