import createUsersAPIs from '@/apis/users'
import createDealersAPIs from '@/apis/dealers'

const api = {
  install (app) {
    app.config.globalProperties.$api = {
      ...createUsersAPIs(app),
      ...createDealersAPIs(app),
    }
  }
}

export default api
