export default {
  parseUserMeDealer: function (data) {
    return {
      dealerId: data.dealer_id,
      accessId: data.access_id,
      userId: data.user_id,
      roleId: data.role_id,
      ownerId: data.owner_id,
      type: data.type,
      permissions: {
        admin: data.permissions.has_admin_permission,
        dealerAdmin: data.permissions.has_dealer_admin_permission,
        installer: data.permissions.has_installer_permission,
        integration: data.permissions.has_integration_permission,
        monitoring: data.permissions.has_monitoring_permission,
        payment: data.permissions.has_payment_permission,
        shareEventDownload: data.permissions.has_share_event_download_permission,
        shareEventResponse: data.permissions.has_share_event_response_permission,
        talkdown: data.permissions.has_talkdown_permission,
        verification: data.permissions.has_verification_permission,
      },
      appAccessPermissions: {
        dealerApp: data.app_access_permissions.has_access_dealer_app,
        monitoringApp: data.app_access_permissions.has_access_monitoring_app,
        mobileApp: data.app_access_permissions.has_access_mobile_app,
      },
      companyInfo:{
        companyEmail: data.company_email,
        companyName: data.company_name,
        logoUrl: data.logo_url,
      },
      dealerInfo:{
        myEmail: data.email,
        myFirstname: data.firstname,
        myLastname: data.lastname,
        hasAccessDealer: data.has_access_dealer,
        invitationStatus: data.invitation_status,
        isAcceptedTos: data.is_accepted_tos,
        isOrderAdmin: data.is_order_admin,
        isActivated: !!+data.is_activated,
        isIpBlocked: !!+data.is_ip_blocked,
        isRegistered: !!+data.is_registered,
        isSigninActivated: !!+data.is_signin_activated,
      },
      auth:{
        isVerify: data.is_verify,
        provider: data.provider,
        providePicture: data.provider_picture,
        tempAuthCode: data.temp_auth_code,
      }
    }
  },
}
