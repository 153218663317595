<template lang="pug">
div
</template>

<style></style>

<script>
export default {
  computed: {
    myEmail: function () {
      return this.$store.getters.userMe.email
    },
    statusGetUserMe: function () {
      return this.$store.getters.statusGetUserMe
    },
    myDealerID: function () {
      return this.$store.getters.userMeDealer.dealerId
    },
    statusGetUserMeDealer: function () {
      return this.$store.getters.statusGetUserMeDealer
    },
  },
  data: function () {
    return {
      errMessage: "",
      //
      refreshingUserMe$i: null,
      refreshingUserMeDealer$i: null,
    }
  },
  watch: {
    myDealerID: function (val) {
      if (!val) {
        this.onNoDealerId()
        return
      }
      console.log(`${this.myEmail} is a member of a dealer.`)
    },
  },
  mounted: function () {
    this.startRefreshingUserMe()
    this.startRefreshingUserMeDealer()
  },
  beforeUnmount: function () {
    this.stopRefreshingUserMe()
    this.stopRefreshingUserMeDealer()
  },
  methods: {
    /////////////////////////////////////////////
    onNoDealerId: function () {
      this.errMessage = `${this.myEmail}<br>is not a member of any dealer.`
      console.warn(this.errMessage)
    },
    /////////////////////////////////////////////

    /////////////////////////////////////////////
    // GET - MY PROFILE
    getUserMe: function () {
      if (!this.$auth.isAuthenticated()) return
      if (this.statusGetUserMe === 'requested') return
      this.$store.dispatch('getUserMe')
    },
    startRefreshingUserMe: function () {
      const interval = 1000 * 60 * 1 // every 1 minute
      this.stopRefreshingUserMe()
      this.getUserMe()
      this.refreshingUserMe$i = setInterval(() => this.getUserMe(), interval)
    },
    stopRefreshingUserMe: function () {
      if (!this.refreshingUserMe$i) return
      clearInterval(this.refreshingUserMe$i)
      this.refreshingUserMe$i = null
    },
    /////////////////////////////////////////////

    /////////////////////////////////////////////
    // GET - MY DEALER
    getUserMeDealer: function () {
      if (!this.$auth.isAuthenticated()) return
      if (this.statusGetUserMeDealer === 'requested') return
      this.$store.dispatch('getUserMeDealer')
    },
    startRefreshingUserMeDealer: function () {
      const interval = 1000 * 60 * 1 // every 1 minute
      this.stopRefreshingUserMeDealer()
      this.getUserMeDealer()
      this.refreshingUserMeDealer$i = setInterval(() => this.getUserMeDealer(), interval)
    },
    stopRefreshingUserMeDealer: function () {
      if (!this.refreshingUserMeDealer$i) return
      clearInterval(this.refreshingUserMeDealer$i)
      this.refreshingUserMeDealer$i = null
    },
    /////////////////////////////////////////////
  }
};
</script>
