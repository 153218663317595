export default app => {
  const v = app.config.globalProperties
  return {
    getDealerEvents: function ({dealerId, beginTimestamp, lastEventId}) {
      return v.$http({
        method: 'get',
        headers: {'Authorization': 'Bearer ' + v.$auth.getAccessToken()},
        baseURL: v.$env.getAPIPrefixV1_1(),
        url: `/dealers/${dealerId}/events`,
        params: {
          sort: "event_id",
          dir: "desc",
          limit: "25",
          filter: [
            lastEventId ? `event_id,${lastEventId},gt` : beginTimestamp ? `device_event.timestamp,${beginTimestamp},gte` : undefined,
            "device_event.type,di,eq",
          ],
        },
        responseType: 'json',
        timeout: 1000 * 5,
      })
    },
  }
}
