import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_DEALER_EVENTS_REQUEST,
  GET_DEALER_EVENTS_SUCCESS,
  GET_DEALER_EVENTS_FAILURE,
} from '@/store/mutationTypes'

export default app => {
  // INIT
  const api = app.config.globalProperties.$api

  // STATE - default
  let initialState = {
    dealerEvents: [],
    dealerSitesWithEvents: {},
    status: {
      getDealerEvents: null,
    },
  }
  // STATE - init
  const state = JSON.parse(JSON.stringify(initialState))

  // GETTERS
  const getters = {
    dealerEvents: function (state) {
      return state.dealerEvents
    },
    lastEventId: function (state, getters) {
      const length = getters.dealerEvents.length
      return length > 0 ? getters.dealerEvents[0].event_id : undefined
    },
    dealerSitesWithEvents: function (state) {
      return state.dealerSitesWithEvents
    },
  }

  // ACTIONS
  const actions = {
    getDealerEvents: function ({commit, getters}, {dealerId, beginTimestamp, lastEventId}) {
      commit(GET_DEALER_EVENTS_REQUEST)
      if (!lastEventId) lastEventId = getters.lastEventId
      return new Promise((resolve, reject) => {
        api.getDealerEvents({dealerId, beginTimestamp, lastEventId}).then(
          res => {
            commit(GET_DEALER_EVENTS_SUCCESS, res.data)
            resolve(res.data)
            commit(PROCESS_API_SUCCESS)
          },
          err => {
            commit(GET_DEALER_EVENTS_FAILURE)
            if (err.response === undefined) {
              reject(err.message)
              return
            }
            reject(err.response)
            commit(PROCESS_API_FAILURE, {
              status: err.response.status,
              statusText: err.response.data.message,
            })
          }
        )
      })
    },
  }

  // MUTATIONS
  const mutations = {
    [RESET_STATE]: function (state) {
      for (let f in state) {
        state[f] = initialState[f]
      }
    },
    [GET_DEALER_EVENTS_REQUEST]: function (state) {
      state.status.getDealerEvents = 'requested'
    },
    [GET_DEALER_EVENTS_SUCCESS]: function (state, data) {
      // PUSH
      data.forEach(v=>{
        state.dealerEvents.push(v)
        if (!(v.site_id in state.dealerSitesWithEvents)) {
          state.dealerSitesWithEvents[v.site_id] = {
            site_id: v.site_id,
            site_name: v.site_name,
            timestamp: 0,
            events: [],
            zones: {},
          }
        }
        state.dealerSitesWithEvents[v.site_id].events.push(v)
        if (v.alarm_zone) {
          state.dealerSitesWithEvents[v.site_id].zones[v.alarm_zone] = true
        }
      })
      // SORT
      state.dealerEvents.sort((a,b) =>  +b.event_id - +a.event_id)
      Object.keys(state.dealerSitesWithEvents).forEach(v=>{
        const events = state.dealerSitesWithEvents[v].events
        events.sort((a,b) =>  +b.event_id - +a.event_id)
        state.dealerSitesWithEvents[v].timestamp = events[events.length - 1].timestamp
      })
      // DONE
      state.status.getDealerEvents = 'successful'
    },
    [GET_DEALER_EVENTS_FAILURE]: function (state) {
      state.status.getDealerEvents = 'failed'
    },
  }

  // RETURN
  return {
    state,
    getters,
    actions,
    mutations
  }
}