export default app => {
  const v = app.config.globalProperties
  return {
    getUserMeIp: function () {
      return v.$http({
        method: 'get',
        headers: {'Authorization': 'Bearer ' + v.$auth.getAccessToken()},
        baseURL: v.$env.getAPIPrefixV1_1(),
        url: '/users/me/ip',
        responseType: 'json',
        timeout: 1000 * 5,
      })
    },
  }
}
