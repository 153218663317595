import me from "./me"

export default app => {
  const v = app.config.globalProperties
  return {
    ...me(app),
    getUserByEmail: function ({email}) {
      return v.$http({
        method: 'get',
        baseURL: v.$env.getAPIPrefixV1_1(),
        url: '/users/find',
        params: {
          email: email,
        },
        responseType: 'json',
        timeout: 1000 * 5,
      })
    },
  }
}
