/////////////
// USER ME //
/////////////
export const GET_USER_ME_REQUEST = 'GET_USER_ME_REQUEST'
export const GET_USER_ME_SUCCESS = 'GET_USER_ME_SUCCESS'
export const GET_USER_ME_FAILURE = 'GET_USER_ME_FAILURE'

export const GET_USER_ME_DEALER_REQUEST = 'GET_USER_ME_DEALER_REQUEST'
export const GET_USER_ME_DEALER_SUCCESS = 'GET_USER_ME_DEALER_SUCCESS'
export const GET_USER_ME_DEALER_FAILURE = 'GET_USER_ME_DEALER_FAILURE'

/////////////////
// USER ME IP  //
/////////////////
export const GET_USER_ME_IP_REQUEST = 'GET_USER_ME_IP_REQUEST'
export const GET_USER_ME_IP_SUCCESS = 'GET_USER_ME_IP_SUCCESS'
export const GET_USER_ME_IP_FAILURE = 'GET_USER_ME_IP_FAILURE'

/////////////
// EVENTS  //
/////////////
export const GET_DEALER_EVENTS_REQUEST = 'GET_DEALER_EVENTS_REQUEST'
export const GET_DEALER_EVENTS_SUCCESS = 'GET_DEALER_EVENTS_SUCCESS'
export const GET_DEALER_EVENTS_FAILURE = 'GET_DEALER_EVENTS_FAILURE'
