import axios from 'axios'

const toolInitial = {
  sortByName : function ( a, b ) {
    if ( a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    if ( a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    return 0;
  },
  wait: function (time) {
    return new Promise(resolve => {
      setTimeout(() => { resolve() }, time)
    })
  },
  isValidIPv4: function (addr) {
    return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(addr)
  },
  isValidEmailAddress (email) {
    let regexp = new RegExp('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-\\+]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')
    if (regexp.test(email)) return true
    return false
  },
  isValidMacAddress (macAddress) {
    let regexp = new RegExp('[0-9A-F]{12}')
    if (regexp.test(macAddress)) return true
    return false
  },
  redirectToUrl: function (url) {
    // similar behavior as an HTTP redirect
    window.location.replace(url)
  },
  openNewTab: function (url) {
    let win = window.open(url, '_blank')
    // Browser has allowed it to be opened
    if (win) {
      win.focus()
      return
    } 
    // Browser has blocked it
    alert('Please allow popups for this website')
  },
  makePhoneCall: function (phoneNumber) {
    window.location.href = 'tel:' + phoneNumber
  },
  sendEmail: function (emailAddress) {
    window.location.href = 'mailto:' + emailAddress
  },
  openMap: function (lat, lng) {
    let url = 'http://maps.apple.com/?q=' + lat + ',' + lng
    this.openNewTab(url)
  },
  playTts: function (text) {
    // TTS is not supported
    if (!('speechSynthesis' in window)) return
    // TTS is supported
    let msg = new SpeechSynthesisUtterance(text)
    let voiceName = 'Google US English'
    msg.voice = window['speechSynthesis'].getVoices().filter(voice => { return voice.name === voiceName })[0]
    window['speechSynthesis'].speak(msg)
  },
  cancelTts: function () {
    // TTS is not supported
    if (!('speechSynthesis' in window)) return
    // TTS is supported
    window['speechSynthesis'].cancel()
  },
  getFormattedDate: function (date) {
    date = new Date(date)
    var dateStr =
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('00' + date.getDate()).slice(-2) +
      '/' +
      date.getFullYear() +
      ' ' +
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2) +
      ':' +
      ('00' + date.getSeconds()).slice(-2)
    return dateStr
  },
  parseLanguageCode: function (language) {
    let result
    switch (language) {
      case 'English (United States)':
        result = 'en-US'
        break
      case 'en-US':
        result = 'English (United States)'
        break
      case 'English (United Kingdom)':
        result = 'en-GB'
        break
      case 'en-GB':
        result = 'English (United Kingdom)'
        break
      case 'Spanish (Spain)':
        result = 'es-ES'
        break
      case 'es-ES':
        result = 'Spanish (Spain)'
        break
      case 'French (France)':
        result = 'fr-FR'
        break
      case 'fr-FR':
        result = 'French (France)'
        break
      case 'Japanese (Japan)':
        result = 'ja-JP'
        break
      case 'ja-JP':
        result = 'Japanese (Japan)'
        break
      case 'Korean (South Korea)':
        result = 'ko-KR'
        break
      case 'ko-KR':
        result = 'Korean (South Korea)'
        break
      default:
        return
    }
    return result
  },
  getTimeZone : async function(lat, lng, timestamp){
    let apiKey = process.env.VUE_APP_GOOGLE_TIMEZONE_API_KEY
    let url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&key=${apiKey}&channel=11&timestamp=${timestamp}`
    let config = {
      method: 'get',
      url: url,
      headers: { }
    };
    
    try { 
      const res = await axios(config)
      return res.data
    } catch(err){
      console.log('getTimeZone in tool.js err',err)
      return
    }
  }
}

const toolVuePlugin = {
  install (app) {
    app.config.globalProperties.$tool = {
      notifySuccess: function(message, caption){
        app.config.globalProperties.$q.notify({
          type: "positive",
          message,
          caption: caption || 'success',
          color: "primary",
        })
      },
      notifyFailure: function (message, caption) {
        app.config.globalProperties.$q.notify({
          type: "negative",
          message,
          caption: caption || 'failed',
          color: "negative",
        })
      },
      ...toolInitial,
    }
  }
}

export default toolVuePlugin
export const tool = toolInitial
