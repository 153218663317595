import axios from 'axios'

const http = {
  install (app) {
    app.config.globalProperties.$axios = axios
    app.config.globalProperties.$http = axios
  }
}

export default http
