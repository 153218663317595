const env = {
  install (app) {
    const getCurrentDomain = function () {
      return window.location.hostname
    }
    const domains = {
      'dev': 'chektdev.com',
      'prod': 'chekt.com',
    }
    app.config.globalProperties.$env = {
      isProduction: function () {
        const domain = getCurrentDomain()+''
        if (domain.includes('chekt.com')) return true
        if (domain.includes('chekt.name')) return true
        if (domain.includes('chektdev.com')) return false
        if (domain.includes('app.local')) return true
        if (domain.includes('localhost')) return false
        return false
      },
      getAPIPrefixV1_1: function () {
        if (this.isProduction()) return `https://api.${domains.prod}/api/v1.1`
        return `https://api.${domains.dev}/api/v1.1`
      },
      getAPIPrefixV1_2: function () {
        if (this.isProduction()) return `https://api.${domains.prod}/api/v1.2`
        return `https://api.${domains.dev}/api/v1.2`
      },
      getAPIPrefixV2: function () {
        if (this.isProduction()) return `https://api.${domains.prod}/api/v2`
        return `https://api.${domains.dev}/api/v2`
      },
      getAuth0Vars: function () {
        if (this.isProduction()) return {
          authDomain: 'auth.chekt.com',
          authClientID: 'bHzjpwmWpjwdOukz2h7FM2u2ND5BPKFa',
        }
        return {
          authDomain: 'auth.chektdev.com',
          authClientID: 'yeJIohNks41mYAiaID29JoxsZESOpEQ8',
        }
      }
    }
  }
}

export default env
