<template lang="pug">
StoreUpdateManager
router-view
</template>

<style lang="sass">
</style>

<script>
import StoreUpdateManager from "@/components/manager/StoreUpdateManager.vue"

export default {
  components: {
    StoreUpdateManager,
  },
}
</script>