import parser from '@/models/userProfile'
import parser2 from '@/models/userMeDealer'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_USER_ME_REQUEST,
  GET_USER_ME_SUCCESS,
  GET_USER_ME_FAILURE,
  GET_USER_ME_DEALER_REQUEST,
  GET_USER_ME_DEALER_SUCCESS,
  GET_USER_ME_DEALER_FAILURE,
  GET_USER_ME_IP_REQUEST,
  GET_USER_ME_IP_SUCCESS,
  GET_USER_ME_IP_FAILURE,
} from '@/store/mutationTypes'

export default app => {
  // INIT
  const api = app.config.globalProperties.$api

  // STATE - default
  let initialState = {
    userMe: {},
    userMeDealer: {},
    userMeIp: {},
    status: {
      getUserMeIp: null,
      getUserMe: null,
      getUserMeDealer: null,
    },
  }
  // STATE - init
  const state = JSON.parse(JSON.stringify(initialState))

  // GETTERS
  const getters = {
    userMe: function (state) {
      return state.userMe
    },
    myDealerId: function (state) {
      return state.userMeDealer.dealerId
    },
    userMeDealer: function (state) {
      return state.userMeDealer
    },
    userMeIp: function (state) {
      return state.userMeIp
    },
    statusGetUserMe: function (state) {
      return state.status.getUserMe
    },
    statusGetUserMeDealer: function (state) {
      return state.status.getUserMeDealer
    },
    statusGetUserMeIp: function (state) {
      return state.status.getUserMeIp
    },
  }

  // ACTIONS
  const actions = {
    getUserMe: function ({commit}) {
      commit(GET_USER_ME_REQUEST)
      return new Promise((resolve, reject) => {
        api.getUserMe().then(
          res => {
            commit(GET_USER_ME_SUCCESS, res.data[0])
            resolve(res.data[0])
            commit(PROCESS_API_SUCCESS)
          },
          err => {
            commit(GET_USER_ME_FAILURE)
            if (err.response === undefined) {
              reject(err.message)
              return
            }
            reject(err.response)
            commit(PROCESS_API_FAILURE, {
              status: err.response.status,
              statusText: err.response.data.message,
            })
          }
        )
      })
    },
    getUserMeDealer: function ({commit}) {
      commit(GET_USER_ME_DEALER_REQUEST)
      return new Promise((resolve, reject) => {
        api.getUserMeDealer().then(
          res => {
            commit(GET_USER_ME_DEALER_SUCCESS, res.data[0])
            resolve(res.data[0])
            commit(PROCESS_API_SUCCESS)
          },
          err => {
            commit(GET_USER_ME_DEALER_FAILURE)
            if (err.response === undefined) {
              reject(err.message)
              return
            }
            reject(err.response)
            commit(PROCESS_API_FAILURE, {
              status: err.response.status,
              statusText: err.response.data.message,
            })
          }
        )
      })
    },
    getUserMeIp: function ({commit}) {
      commit(GET_USER_ME_IP_REQUEST)
      return new Promise((resolve, reject) => {
        api.getUserMeIp().then(
          res => {
            commit(GET_USER_ME_IP_SUCCESS, res.data)
            resolve(res.data)
            commit(PROCESS_API_SUCCESS)
          },
          err => {
            commit(GET_USER_ME_IP_FAILURE)
            if (err.response === undefined) {
              reject(err.message)
              return
            }
            reject(err.response)
            commit(PROCESS_API_FAILURE, {
              status: err.response.status,
              statusText: err.response.data.message,
            })
          }
        )
      })
    },
  }

  // MUTATIONS
  const mutations = {
    [RESET_STATE]: function (state) {
      for (let f in state) {
        state[f] = initialState[f]
      }
    },
    [GET_USER_ME_REQUEST]: function (state) {
      state.status.getUserMe = 'requested'
    },
    [GET_USER_ME_SUCCESS]: function (state, data) {
      state.userMe = parser.parseUserProfile(data)
      state.status.getUserMe = 'successful'
    },
    [GET_USER_ME_FAILURE]: function (state) {
      state.status.getUserMe = 'failed'
    },
    [GET_USER_ME_DEALER_REQUEST]: function (state) {
      state.status.getUserMeDealer = 'requested'
    },
    [GET_USER_ME_DEALER_SUCCESS]: function (state, data) {
      state.userMeDealer = parser2.parseUserMeDealer(data)
      state.status.getUserMeDealer = 'successful'
    },
    [GET_USER_ME_DEALER_FAILURE]: function (state) {
      state.status.getUserMeDealer = 'failed'
    },
    [GET_USER_ME_IP_REQUEST]: function (state) {
      state.status.getUserMeIp = 'requested'
    },
    [GET_USER_ME_IP_SUCCESS]: function (state, data) {
      state.userMeIp = data
      state.status.getUserMeIp = 'successful'
    },
    [GET_USER_ME_IP_FAILURE]: function (state) {
      state.status.getUserMeIp = 'failed'
    },
  }

  // RETURN
  return {
    state,
    getters,
    actions,
    mutations
  }
}