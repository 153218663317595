import auth0js from 'auth0-js'

const auth = {
  install (app) {
    // /////////////////////////////////
    // INIT - vars
    const tokenKey = 'chekt::access_token'
    let accessToken = 'someAccessToken'
    let expiresAt = 0
    // /////////////////////////////////

    // /////////////////////////////////
    // INIT - auth0
    const auth0Vars = app.config.globalProperties.$env.getAuth0Vars()
    const auth0 = new auth0js.WebAuth({
      domain: auth0Vars.authDomain,
      clientID: auth0Vars.authClientID,
      redirectUri: window.origin + '/callback' + window.location.search,
      responseType: 'token id_token',
      scope: 'openid',
    })
    const auth0Login = function () {
      return auth0.authorize({login_hint: undefined})
    }
    const auth0Logout = function () {
      return auth0.authorize({
        returnTo: window.location.origin + window.location.search,
        clientID: auth0Vars.authClientID
      })
    }
    const auth0CheckSession = function () {
      return new Promise((resolve, reject) => {
        auth0.checkSession({}, (err, authResult) => {
          if (err) return reject(err)
          resolve(authResult.idToken)
        })
      })
    }
    const auth0ParseHash = function () {
      if (!window.location.pathname.includes('/callback')) return
      return new Promise((resolve, reject) => {
        auth0.parseHash({
          __enableIdPInitiatedLogin: true,
        }, (err, authResult) => {
          if (err) return reject(err)
          resolve(authResult.idToken)
        })
      })
    }
    // /////////////////////////////////

    // /////////////////////////////////
    // INIT - funcs
    const loadAccessToken = function () {
      accessToken = localStorage.getItem(tokenKey)
      return accessToken
    }
    const storeAccessToken = function (token) {
      accessToken = token
      localStorage.setItem(tokenKey, token)
    }
    const parseAccessToken = function (token) {
      if (typeof token !== "string") throw new Error('invalid token')
      const m = token.split('.')
      if (m.length !== 3) throw new Error('misformed token')
      const decoded = JSON.parse(Buffer.from(m[1], 'base64'))
      if (!(decoded instanceof Object)) throw new Error('failed parsing')
      return decoded
    }
    const checkStoredAccessToken = async function () {
      // ROUTINE 1 - check `/callback` after `login`
      if (window.location.pathname.includes('/callback')) {
        try {
          const idToken = await auth0ParseHash()
          storeAccessToken(idToken)
          window.location.replace(window.origin)
        } catch (err) {
          alert(`${err.error}: ${err.errorDescription}`)
          console.warn(err)
        }
        return
      }

      // ROUTINE 2
      try {
        // INIT
        const timeNow = new Date().getTime() / 1000
        const token = loadAccessToken()
        const decoded = parseAccessToken(token)
        expiresAt = +decoded.exp
        const validFor = expiresAt - timeNow
        // CASE 1 - token is valid
        if (validFor > 10) return
        
        // CASE 2 - token is invalid
        const idToken = await auth0CheckSession()
        storeAccessToken(idToken)
        return
      } catch (err) {
        console.warn(err)
        if (err.error === 'login_required') {
          auth0Login()
        }
      }

      // ROUTINE 3 - when ROUTINE 2 failed
      try {
        const idToken = await auth0CheckSession()
        storeAccessToken(idToken)
      } catch (err) {
        console.warn(err)
        auth0Login()
      }
    }
    // /////////////////////////////////

    // /////////////////////////////////
    // START - checkingStoredAccessToken 
    checkStoredAccessToken()
    setInterval(() => checkStoredAccessToken(), 10 * 1000)
    // /////////////////////////////////

    app.config.globalProperties.$auth = {
      getAccessToken: function () {
        return accessToken
      },
      getExpiresAt: function () {
        return expiresAt
      },
      getVaildFor: function () {
        const timeNow = new Date().getTime() / 1000
        return expiresAt - timeNow
      },
      isAuthenticated: function () {
        return !!this.getVaildFor()
      },
      logout: auth0Logout,
    }
  }
}

export default auth
