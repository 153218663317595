import eventsRoutes from '@/_routes/events/_router'

const routes = [
    {
      path: '/',
      redirect: '/events'
    },
    ...eventsRoutes,
]

import { createRouter, createWebHistory } from 'vue-router'
export default createRouter({
  history: createWebHistory(),
  routes,
})