import Notify from 'quasar/src/plugins/Notify.js';
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
} from './mutationTypes'

let initialState = {
  isOnline: true,
  onlineStatusChangeCounter: 0,
  isApiRateLimitLocked: false,
  apiRateLimitLockCounter: 0,
  connectionStatusText: '',
}

// initial state
const state = JSON.parse(JSON.stringify(initialState))

// getters
const getters = {
  isOnline: function (state) {
    return state.isOnline
  },
  isApiRateLimitLocked: function (state) {
    return state.isApiRateLimitLocked
  },
  onlineStatusChangeCounter: function (state) {
    return state.onlineStatusChangeCounter
  },
  apiRateLimitLockCounter: function (state) {
    return state.apiRateLimitLockCounter
  },
  connectionStatusText: function (state) {
    return state.connectionStatusText
  },
}

// actions
const actions = {
  initializeData: function () {
    return new Promise((resolve) => {
      // dispatch('something')
      resolve()
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      state[f] = initialState[f]
    }
  },
  [PROCESS_API_SUCCESS]: function (state) {
    if (state.isOnline === false) {
      state.onlineStatusChangeCounter++
      console.log('PROCESS_API_SUCCESS: ONLINE')
    }
    if (state.isApiRateLimitLocked === true) {
      state.apiRateLimitLockCounter++
      console.log('PROCESS_API_SUCCESS: API rate limit unlocked')
    }
    state.isOnline = true
    state.isApiRateLimitLocked = false
    state.connectionStatusText = ''
  },
  [PROCESS_API_FAILURE]: function (state, {status, statusText}) {
    let isOnline = false
    let isApiRateLimitLocked = false
    switch(status) {
      case 0:
        isOnline = false
        isApiRateLimitLocked = false
        state.connectionStatusText = `[status code: ${status}] offline`
        break
      case 401:
        isOnline = true
        isApiRateLimitLocked = false
        state.connectionStatusText = `[status code: ${status}] ` + JSON.stringify(statusText)
        break
      case 403:
        isOnline = true
        isApiRateLimitLocked = false
        state.connectionStatusText = `[status code: ${status}] ` + JSON.stringify(statusText)
        break
      case 429:
        isOnline = true
        isApiRateLimitLocked = true
        state.connectionStatusText = `[status code: ${status}] API rate limit lock`
        break
      default:
        isOnline = true
        isApiRateLimitLocked = false
        state.connectionStatusText = `[status code: ${status}] unknown API failure: ` + JSON.stringify(statusText)
    }
    if (state.isOnline !== isOnline) {
      state.onlineStatusChangeCounter++
    }
    if (state.isApiRateLimitLocked !== isApiRateLimitLocked) {
      state.apiRateLimitLockCounter++
    }
    state.isOnline = isOnline
    console.warn('PROCESS_API_FAILURE: ' + state.connectionStatusText)
    Notify.create({
      message: state.connectionStatusText,
      color: 'negative',
      icon: 'report_problem',
      timeout: 5000,
      multiLine: true,
      actions: [{label: 'Dismiss', color: 'white'}]
    })
  },
}

export default {
  state,
  actions,
  getters,
  mutations
}
