import './styles/quasar.sass'
import './styles/chekt-style.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import './styles/material-icons.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';

export default {
  config: {},
  plugins: {
    Dialog,
    Notify,
    Loading
  }
}
