import { createStore } from 'vuex'
import rootModule from './root-module'
import createModules from './modules/_index'

export default app => {
  const debug = !app.config.globalProperties.$env.isProduction()
  return createStore({
    state: rootModule.state,
    actions: rootModule.actions,
    getters: rootModule.getters,
    mutations: rootModule.mutations,
    modules: {
      ...createModules(app)
    },
    strict: debug
  })
}
